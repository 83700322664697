import React from "react";

import { MarkdownContent } from "./Content";
import PreviewCompatibleImage from "./PreviewCompatibleImage";

export default ({ image, className, name, title, children, bio_content }) => (
  <article className={`columns ${className}`} key={name}>
    <aside className="w-full md:w-3/12">
      <figure className="w-3/12 md:w-full bg-accent rounded-full overflow-hidden">
        <PreviewCompatibleImage
          imageInfo={{
            image: image,
            alt: name,
          }}
        />
      </figure>
    </aside>

    <div className="w-full md:w-9/12">
      <h3 className="font-heading text-2xl sm:text-3xl md:text-4xl text-navy">
        {name}
      </h3>
      <h4 className="font-medium text-sm md:text-lg xl:text-xl xl:mt-1 text-accent">
        {title}
      </h4>
      <div class="prose max-w-full lg:prose-lg">
        {children}
      </div>
      <MarkdownContent content={bio_content} className="prose max-w-full lg:prose-lg" />
    </div>
  </article>
);
