import React from "react";
import Layout from "../components/Layout";
import { graphql } from "gatsby";
import { Helmet } from "react-helmet";
import BioCard from "../components/BioCard";
import AdvisoryBioItem from "../components/AdvisoryBioItem";

import logo from "../img/signature-solid-white.svg";

const DefaultPage = ({ data }) => {
  const {
    markdownRemark: post,
  } = data;

  return (
    <Layout
      headerClassName="bg-accent curve-br"
      navbarLogo={logo}
      className={post.frontmatter.accentClass}
      headerTitle={post.frontmatter.heading}
    >
      <Helmet titleTemplate="%s - ScriptDrop">
        <title>{`${post.frontmatter.title}`}</title>
      </Helmet>

      <section className="container mt-4 lg:mt-8">
        <div className="columns">
          <div className="w-full lg:w-10/12 space-y-8 md:space-y-12">
            { post.frontmatter.bios &&
              post.frontmatter.bios.map((bio) => (
                <BioCard
                  name={bio.name}
                  title={bio.title}
                  className={bio.accentClass}
                  image={bio.image}
                  bio_content={bio.bio_content}
                />
              )
            )}
          </div>
        </div>
        <div className="columns">
          <div className="w-full lg:w-10/12 mt-4 lg:mt-8 ">
            <hr className="border-accent-p2" />

            <h2 className="mt-4 md:mt-8 mb-4 text-xl sm:text-2xl md:text-3xl text-navy">
              Advisory Council
            </h2>

            <ul className="space-y-4 md:text-lg">
              { post.frontmatter.advisory_bios &&
                post.frontmatter.advisory_bios.map((bio) => (
                  <AdvisoryBioItem
                    name={bio.name}
                    image={bio.image}
                    bio_content={bio.bio_content}
                  />
                )
              )}
            </ul>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default DefaultPage;

export const pageQuery = graphql`
  query biopageQuery($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        heading
        accentClass
        bios {
          title
          name
          accentClass
          bio_content
          image {
            childImageSharp {
              fluid(maxWidth: 256, quality: 80, traceSVG: {background: "transparent", color: "#003052"}) {
                ...GatsbyImageSharpFluid_withWebp_tracedSVG
              }
            }
          }
        }
        advisory_bios {
          name
          bio_content
          image {
            childImageSharp {
              fluid(maxWidth: 256, quality: 80, traceSVG: {background: "transparent", color: "#DBE2E8"}) {
                ...GatsbyImageSharpFluid_withWebp_tracedSVG
              }
            }
          }
        }
      }
    }
  }
`;
