import React from "react";
import { MarkdownContent } from "./Content";
import PreviewCompatibleImage from "./PreviewCompatibleImage";

export default ({ image, name, children, bio_content }) => (
  <li className="columns">
    <aside className="w-full md:w-3/12 lg:w-2/12">
      <PreviewCompatibleImage
        imageInfo={{
          image: image,
          className: "w-2/12 md:w-full rounded-xl",
          alt: name,
        }}
      />
    </aside>
    <div className="w-full md:w-9/12 lg:w-10/12 prose max-w-none">
      <h3>{name}</h3>
      {children}
      <MarkdownContent content={bio_content} className="prose max-w-full" />
    </div>
  </li>
);
